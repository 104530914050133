import { ApiReturn } from '@/composables/use-api';

export type Notification = ApiReturn<'getNotifications'>[0];

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notificationsById: {} as { [id: string]: Notification }
  }),

  getters: {
    notifications: (state) => Object.values(state.notificationsById)
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt)),
    hasNewNotifications: (state) => !!Object.values(state.notificationsById)
      .find((n) => n.isRead === false)
  },

  actions: {
    async fetchNotifications() {
      const { data } = useApi<Notification[], any, any, Notification[]>({
        url: '/v1/notifications',
        method: 'GET'
      }, {
        transform: (response) => response.data
      });

      if (data.value) {
        data.value.forEach((notification) => {
          this.notificationsById[notification.id] = notification;
        });
      }
    },

    async markNotificationRead(notificationId: string) {
      this.notificationsById[notificationId].isRead = true;

      await useApi({
        url: `/v1/notifications/${notificationId}`,
        method: 'POST',
        data: { isRead: true }
      });
      await this.fetchNotifications();
    }
  }
});
